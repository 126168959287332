@import '~@ui/styles/tools';
@import '../../../../styles/variables.scss';

@mixin small-breakpoint {
    @media screen and (max-width: 1470px) {
        @content;
    }
}

.container {
    display: grid;
    gap: 24px;
    grid-template-columns: [start] 1fr [center] 1fr [end];
    grid-template-rows: [start] minmax(336px, auto) [center] max-content [end];
    grid-template-areas:
        'title steps'
        'calculator form';
}

.title-card {
    grid-area: title;
    background-color: #20836d !important;
    position: relative;
}

.calculator-card {
    grid-area: calculator;
    background-color: $color-page-background !important;
    display: flex;
    flex-direction: column;
}

.steps-card {
    grid-area: steps;
    background-color: transparent;
    display: flex;
    padding: 0 !important;
}

.button-card {
    grid-area: form;
    background-color: $color-page-background !important;
    display: flex;
    overflow: hidden;
    position: relative;
}

@include small-breakpoint {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, max-content);
        grid-template-areas:
            'title'
            'steps'
            'calculator'
            'form';
    }
}

@include media-tablet {
    .container {
        gap: 12px;
    }
}

@include media-tablet-portrait {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 168px max-content max-content max-content;
        grid-template-areas:
            'title'
            'steps'
            'calculator'
            'form';
    }
}
