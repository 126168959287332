@import '~@ui/styles/tools';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1470px) {
        @content;
    }
}

.steps {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 20px;
    counter-reset: step-counter;
    list-style: none;
    width: 100%;

    margin: 0 !important;
    padding: 0 !important;
}

li.steps-item {
    margin: 0;
}

.steps-item:first-of-type {
    .step {
        justify-content: space-between;
        color: #000;
        cursor: pointer;

        &::before {
            color: rgba(0, 0, 0, 0.5);
        }
        &::after {
            position: absolute;
            content: '';
            height: 51px;
            width: 8px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #20836d;
            border-radius: 5px;
        }
    }
}

.step {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 30px 32px 30px 82px;

    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    border-radius: 12px;
    background-color: #f6f7f8;
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    color: rgba(0, 0, 0, 0.5);
    counter-increment: step-counter;

    &::before {
        position: absolute;
        box-sizing: border-box;
        content: counter(step-counter);

        display: flex;
        justify-content: center;
        align-items: center;

        width: 37px;
        height: 37px;
        left: 32px;
        top: 50%;
        transform: translateY(-50%);

        border-radius: 50%;
        border: 2px solid #f5f6f7;
        box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
        color: rgba(0, 0, 0, 0.35);
        background-color: #fff;
    }

    &.step--button {
        border: none;
        outline: none;
        box-shadow: none;
        text-align: left;

        width: 100%;
        height: 100%;

        * {
            transition: color 0.2s ease-in;
        }

        &:hover {
            * {
                color: #165a4b;
            }
        }
    }
}

.text {
    padding: 0;
    margin: 0;

    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    color: inherit;
}

@include medium-breakpoint {
    .text {
        font-size: 20px;
    }
}

@include small-breakpoint {
    .text {
        font-size: 26px;
    }
}

@include media-tablet {
    .steps {
        row-gap: 10px;
    }
}

@include media-tablet-portrait {
    .steps {
        grid-template-rows: repeat(3, max-content);
    }

    .step {
        padding: 20px 28px 20px 78px;
        height: fit-content;

        font-size: 16px;

        &::before {
            width: 30px;
            height: 30px;
            left: 28px;
            font-size: 16px;
        }
    }

    .text {
        font-size: 16px;
    }
}

@include media-mobile {
    .text {
        max-width: 25ch;
    }
}
