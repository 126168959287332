@import '~@ui/styles/tools';

.title {
    font-size: 48px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0px;
    color: #fff;
    max-width: 21ch;
}

.bg-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    pointer-events: none;
}

.bg-image {
    left: unset !important;
    width: unset !important;
    pointer-events: none;
}

@include media-tablet {
    .title {
        font-size: 28px;
    }
}

@include media-tablet-portrait {
    .title {
        font-size: 24px;
    }
}
