@import "~@ui/styles/tools";

.calculator-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calculator-button {
    max-width: fit-content;
    flex: 1;

    & span {
        font-size: 14px;
        white-space: nowrap;
    }

    & button {
        padding: 0 16px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@include media-mobile {
    .calculator-button {
        & span {
            font-size: 12px;
        }

        & button {
            padding: 0 16px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}