@import '~@ui/styles/tools';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1470px) {
        @content;
    }
}

.content {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0px;
    margin-bottom: 16px;
}

.description {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #8c8c8c;
}

.button-wrapper {
    margin-top: auto;
    max-width: 243px;
    width: 100%;

    .button {
        width: 100%;
        font-size: 20px;
        height: 66px;
        font-weight: 500;
    }
}

.image-wrapper {
    position: relative;
    aspect-ratio: 1.84;
    width: calc(100% - 250px);
    pointer-events: none;

    img {
        pointer-events: none;
        left: unset !important;
        transform: translateX(15%) translateY(6%);
        aspect-ratio: 1.84;
        width: 120% !important;
        height: auto !important;
    }
}

@include small-breakpoint {
    .image-wrapper {
        width: max(calc(70% - 250px), 500px);
        margin-left: auto;
        img {
            width: 100% !important;
            transform: translateX(10%) translateY(0);
        }
    }
}

@include media-tablet {
    .content {
        max-width: 300px;
    }

    .image-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(25deg);
        width: 100%;
        margin-left: unset;

        img {
            width: 60% !important;
            transform: translateY(-45%) translateX(0%);
        }
    }
}

@include media-tablet-portrait {
    .content {
        width: 100%;
        max-width: unset;
    }

    .title {
        font-size: 20px;
        line-height: 110%;

        max-width: 250px;
    }

    .description {
        font-size: 16px;

        max-width: 250px;
    }

    .button-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .button-wrapper .button {
        height: 55px;
        width: 100%;
        font-size: 14px;
    }
}
